<template>
  <div class="frame" :class="wall_groesse">
    <h1 class="uhr" :class="wall_groesse">{{ uhranzeigen }}</h1>
  </div>
</template>
<script>
import Store from "@/store/index";
export default {
  name: "TheScreen1",
  components: {
    //...mapGetters({ cartSum: "cartTotal" }), // wenn auf andere Variable übergeben wird
  },
  data() {
    return {
      timestamp: "",
      connection: null,
      //uhr_typ: "klein",
    };
  },

  computed: {
    api_url() {
      return Store.getters.activeApi();
    },
    wall_groesse() {
      return Store.getters.activeWall();
    },
    websocket() {
      return Store.getters.activeWebsocket();
    },
    wett_aktiv() {
      return Store.getters.activeWebsocket().aktuhr;
    },
    uhranzeigen() {
      if (this.websocket && typeof this.wett_aktiv !== "undefined") {
        return this.websocket.uhren[this.wett_aktiv];
      }
      return "";
    },
  },

  methods: {
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    getNow() {
      const today = new Date();
      const time =
        this.addZero(today.getHours()) +
        ":" +
        this.addZero(today.getMinutes()) +
        ":" +
        this.addZero(today.getSeconds());
      this.timestamp = time;
    },

    setUhrWettbewerb(wettid) {
      var msg = {
        type: "message",
        date: Date.now(),
        aktuhr: parseInt(wettid),
      };

      // Send the msg object as a JSON-formatted string.
      this.connection.send(JSON.stringify(msg));
    },
  },

  emits: {},
  mounted() {
    //this.connect_ws();
    this.getNow();
    //setInterval(this.getNow, 500);
  },
};
</script>
<style scoped>
.frame {
  width: 1024px;
  height: 128px;
  color: white;
  display: flex;
  align-items: center;
}

.frame.klein {
  width: 512px;
}
.uhr {
  margin: auto;
  height: 128px;
  font-weight: bold;
  font-size: 9em;
}
.uhr.klein {
  height: 113px;
  font-size: 7.4em;
}
</style>
